import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/Seo';

const NotFoundPage = ({ location }) => (
  <Layout page="Page not found" location={location}>
    <Seo
      manualTags={{
        seoTitle: 'Page not found',
        seoDescription: '',
      }}
    />
    <section className="aq-feature-bg bg-light-blue pb-9 pb-lg-16 light-blue-overlay">
      <div className="container-fluid">
        <div className="component-wrap">
          <div className="row gx-0">
            <div className="col-lg-5 mt-8 left">
              <h1 className="h1-two-line-v2 green mt-5 mb-14">
                <span>Page not</span>
                <br />
                <span>found!</span>
              </h1>
              <div className="pt-3">
                <p className="lead">
                  We can't seem to find the page you're looking for. Return to
                  the homepage or click one of the links above.
                </p>
                <Link className="btn btn-teal mt-5" to="/">
                  Visit the home page
                </Link>
              </div>
            </div>
            <div className="col-lg-7 image right d-none d-lg-block">
              <img
                src="/images/Characters/Group-Pose.svg"
                className="w-100"
                alt="The Aquanauts in a group pose"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
